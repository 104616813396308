import * as styles from "./LabelCell.module.scss";
import * as Tooltip from "@radix-ui/react-tooltip";
import * as React from "react";
import { isMobile } from "react-device-detect";

declare global {
  interface Window {
    opera: any;
    MSStream: any;
  }
}

type LabelCellProps = { label: string; tooltip: string };
export const LabelCell = (props: LabelCellProps): JSX.Element => {
  return (
    <div className={styles.labelCell}>
      <p className={styles.labelCell__paragraph}>{props.label}</p>
      {isMobile ? (
        <DeviceTooltip tooltip={props.tooltip} />
      ) : (
        <Tooltip.Provider delayDuration={800} skipDelayDuration={500}>
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger asChild>
              <button className="btn !tw-mb-0 tw-mr-1 !tw-rounded-full tw-border-none tw-bg-none !tw-p-4 tw-p-4 tw-text-[#a3a3a3] hover:!tw-cursor-default">
                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="inherit">
                  <g clipPath="url(#a)">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.333}
                      d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.333 8a6.667 6.667 0 0 1 13.334 0Z"
                    />
                  </g>
                  <defs>
                    <clipPath id="a">
                      <path d="M0 0h16v16H0z" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className={styles.TooltipContent} sideOffset={-12}>
                {props.tooltip}
                <Tooltip.Arrow className={styles.TooltipArrow} />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      )}
    </div>
  );
};

const DeviceTooltip = (props: { tooltip: string }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Tooltip.Provider>
      <Tooltip.Root open={isOpen}>
        <Tooltip.Trigger asChild>
          <button className="tw tw-mr-1 tw-border-none tw-bg-none !tw-p-4 tw-text-[#a3a3a3]" onClick={() => setIsOpen(!isOpen)}>
            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="inherit">
              <g clipPath="url(#a)">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.333}
                  d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.333 8a6.667 6.667 0 0 1 13.334 0Z"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path d="M0 0h16v16H0z" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content onPointerDownOutside={() => setIsOpen(false)} className={styles.TooltipContent}>
            {props.tooltip}
            <Tooltip.Arrow className={styles.TooltipArrow} />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
