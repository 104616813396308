import { useIsMobile } from "../../hooks/useIsMobile";
import { Heading } from "../Heading/Heading";
import { MotionConfig, motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

type Props = {
  setShowPortal: (fields: Record<any, string>) => void;
};

export const AboveTheFold = (props: Props): JSX.Element => {
  const { setShowPortal } = props;
  const isMobile = useIsMobile(620);

  const heading = ["The simplest way to craft", "captivating", "visual stories"];

  const duration = 0.8;
  const initial = { opacity: 0, filter: "blur(5px)" };
  const animate = { opacity: 1, filter: "blur(0px)" };
  return (
    <MotionConfig reducedMotion="user">
      <div className="tw-flex tw-flex-col tw-items-center tw-pt-[111px] !tw-text-center max-lg:tw-pt-[24px] max-sm:!tw-h-auto max-sm:!tw-pb-0">
        <div className="tw-flex-col tw-items-center max-container:!tw-w-[83%] max-sm:!tw-w-[90%]  min-container:!tw-w-[60%] min-lg:tw-flex">
          <StaticImage
            src="../../../static/media/logos/logo-white.svg"
            alt="Shorthand logo"
            aria-hidden="true"
            placeholder="blurred"
            width={86}
            height={65}
            className="tw-mb-9 !tw-hidden tw-h-[65px] max-lg:!tw-block"
            objectFit="contain"
          />
          {isMobile ? (
            <Heading headerType={1}>
              {heading[0]}
              <span className="tw-font-PassengerSerif tw-italic"> {heading[1]} </span>
              {heading[2]}
            </Heading>
          ) : (
            <>
              <motion.div
                initial={{ opacity: 0, translateY: 0 }}
                animate={{ opacity: 1, translateY: -3 }}
                transition={{ duration, delay: 0.1 }}
                key={"heading-block-1"}
              >
                <Heading headerType={1}>
                  {heading[0].split(" ").map((word, index) => (
                    <motion.span
                      initial={{ opacity: 0, filter: "blur(5px)", translateY: 5 }}
                      animate={{ opacity: 1, filter: "blur(0px)", translateY: 0 }}
                      transition={{ duration, delay: index * 0.1 }}
                      key={word}
                    >
                      {word}{" "}
                    </motion.span>
                  ))}
                </Heading>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, translateY: 0 }}
                animate={{ opacity: 1, translateY: -3 }}
                transition={{ duration, delay: 0.3 }}
                key={"heading-block-2"}
              >
                <Heading headerType={1}>
                  {heading[1].split(" ").map((word, index) => (
                    <motion.span
                      initial={{ opacity: 0, filter: "blur(5px)", translateY: 5 }}
                      animate={{ opacity: 1, filter: "blur(0px)", translateY: 0 }}
                      transition={{ duration, delay: index * 0.2 }}
                      key={word}
                      className="tw-font-PassengerSerif tw-italic"
                    >
                      {word}{" "}
                    </motion.span>
                  ))}
                  <motion.span
                    initial={{ opacity: 0, filter: "blur(5px)", translateY: 5 }}
                    animate={{ opacity: 1, filter: "blur(0px)", translateY: 0 }}
                    transition={{ duration, delay: 0.3 }}
                    key={"item-2"}
                    className="tw-font-CircularXXSub tw-not-italic"
                  >
                    {heading[2]}{" "}
                  </motion.span>
                </Heading>
              </motion.div>
            </>
          )}
          <motion.div initial={{ translateY: 5 }} animate={{ translateY: 0 }} transition={{ duration, delay: 0.3 }} key={"item-3"}>
            <Heading
              headerType={2}
              className="tw-w-full tw-pt-[18px] !tw-text-center !tw-text-2xl !tw-font-normal !tw-text-grey_scale-200 max-sm:!tw-w-full max-sm:!tw-text-xl"
            >
              <motion.span initial={{ ...initial }} animate={{ ...animate }} transition={{ duration, delay: 0.6 }} key={"item-3"}>
                How you tell your story matters. Deliver up to 10x higher engagement with Shorthand, the{" "}
                <span className="tw-font-PassengerSerif tw-italic">beautifully simple</span> story design platform for the web.
              </motion.span>
            </Heading>
            <motion.div
              initial={{ opacity: 0, filter: "blur(5px)" }}
              animate={{ opacity: 1, filter: "blur(0px)" }}
              transition={{ duration, delay: 0.6 }}
              key={"item-4"}
              className="tw-mt-12 tw-flex tw-justify-center tw-gap-[12px] max-sm:!tw-mt-8"
            >
              <a
                href="https://app.shorthand.com/signup"
                className="btn btn-lg btn-gradient !tw-m-0 !tw-font-bold max-xl:!tw-text-lg max-sm:!tw-text-sm"
              >
                Start creating
              </a>
              <button
                onClick={() =>
                  setShowPortal({
                    interestedIn: "demo",
                    message: "booking a demo of the Shorthand platform",
                  })
                }
                className="btn btn-secondary btn-inverse btn-lg !tw-m-0 !tw-font-bold max-xl:!tw-text-lg max-sm:!tw-text-sm"
              >
                Book a demo
              </button>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </MotionConfig>
  );
};
