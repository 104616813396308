import { useIsMobile } from "../../../hooks/useIsMobile";
import { Option, SelectSection } from "../SelectSection/SelectSection";
import { Cell } from "./Cell";
import { CtaCell } from "./CtaCell";
import * as styles from "./FeaturesMatrix.module.scss";
import { FeaturesTable } from "./FeaturesTable";
import { HeaderCell } from "./HeaderCell";
import { LabelCell } from "./LabelCell";
import {
  ADMIN_SECURITY_FEATURES,
  ANALYTICS_FEATURES,
  BRAND_FEATURES,
  COLLABORATION_FEATURES,
  CUSTOMER_SUCCESS_FEATURES,
  Feature,
  PUBLISHING_FEATURES,
  STORY_FEATURES,
  TECHNICAL_SUPPORT_FEATURES,
} from "./allPlanFeatures";
import { createColumnHelper } from "@tanstack/react-table";
import * as React from "react";

type FeaturesMatrixProps = {
  handleEnterpriseClick: () => void;
  handleTeamClick: () => void;
  handleBusinessClick: () => void;
  handleStarterClick: () => void;
};

const plans = [
  { label: "Starter", value: "Starter" },
  { label: "Team", value: "Team" },
  { label: "Business", value: "Business" },
  { label: "Enterprise", value: "Enterprise" },
];

export const FeaturesMatrix = (props: FeaturesMatrixProps): JSX.Element => {
  const { handleBusinessClick, handleEnterpriseClick, handleTeamClick, handleStarterClick } = props;
  const [selectedPlan, setSelectedPlan] = React.useState<{ label: string; value: string }>({ label: "Starter", value: "Starter" });
  const columnHelper = React.useMemo(() => createColumnHelper<Feature>(), []);

  const isMobile = useIsMobile(920);

  const sharedColumns = [
    columnHelper.accessor(row => row.starter, {
      id: "starter",
      header: () => <HeaderCell cellValue="Starter" />,
      cell: info => <Cell cellValue={info.getValue()} planName="starter" />,
      footer: () => <CtaCell handleFunction={handleStarterClick} ctaText="Get started" />,
    }),
    columnHelper.accessor(row => row.team, {
      id: "team",
      header: () => <HeaderCell cellValue="Team" />,
      cell: info => <Cell cellValue={info.getValue()} planName="team" />,
      footer: () => <CtaCell handleFunction={handleTeamClick} ctaText="Get started" />,
    }),
    columnHelper.accessor(row => row.business, {
      id: "business",
      header: () => <HeaderCell cellValue="Business" />,
      cell: info => <Cell cellValue={info.getValue()} planName="business" />,
      footer: () => <CtaCell handleFunction={handleBusinessClick} ctaText="Request free trial" />,
    }),
    columnHelper.accessor(row => row.enterprise, {
      id: "enterprise",
      header: () => <HeaderCell cellValue="Enterprise" />,
      cell: info => <Cell cellValue={info.getValue()} planName="enterprise" />,
      footer: () => <CtaCell handleFunction={handleEnterpriseClick} ctaText="Contact sales" />,
    }),
  ];

  const storyFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <>Story design & media</>,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const brandFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <>Brand</>,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const publishingFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <>Publishing</>,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const analyticsFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <>Analytics & Tag managers</>,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const collaborationFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <>Collaboration</>,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const technicalSupportFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <>Technical support & services</>,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const adminSecurityFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <>Admin & Security</>,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    ...sharedColumns,
  ];
  const customerSuccessFeaturesColumns = [
    columnHelper.accessor("label", {
      id: "label",
      header: () => <>Customer success</>,
      cell: info => <LabelCell label={info.getValue().value} tooltip={info.getValue().tooltip} />,
    }),
    columnHelper.accessor(row => row.starter, {
      id: "starter",
      header: () => <HeaderCell cellValue="Starter" />,
      cell: info => <Cell cellValue={info.getValue()} planName="starter" />,
    }),
    columnHelper.accessor(row => row.team, {
      id: "team",
      header: () => <HeaderCell cellValue="Team" />,
      cell: info => <Cell cellValue={info.getValue()} planName="team" />,
    }),
    columnHelper.accessor(row => row.business, {
      id: "business",
      header: () => <HeaderCell cellValue="Business" />,
      cell: info => <Cell cellValue={info.getValue()} planName="business" />,
    }),
    columnHelper.accessor(row => row.enterprise, {
      id: "enterprise",
      header: () => <HeaderCell cellValue="Enterprise" />,
      cell: info => <Cell cellValue={info.getValue()} planName="enterprise" />,
    }),
  ];

  const tableData = [
    STORY_FEATURES,
    BRAND_FEATURES,
    PUBLISHING_FEATURES,
    COLLABORATION_FEATURES,
    ANALYTICS_FEATURES,
    ADMIN_SECURITY_FEATURES,
    TECHNICAL_SUPPORT_FEATURES,
    CUSTOMER_SUCCESS_FEATURES,
  ];
  const tableColumns = [
    storyFeaturesColumns,
    brandFeaturesColumns,
    publishingFeaturesColumns,
    collaborationFeaturesColumns,
    analyticsFeaturesColumns,
    adminSecurityFeaturesColumns,
    technicalSupportFeaturesColumns,
    customerSuccessFeaturesColumns,
  ];
  const memoDesktopTableUi = React.useMemo(() => {
    return (
      <div className="tw-animate-fadeIn">
        {tableData.map((table, index) => {
          return <FeaturesTable key={`desktop-table-${index}`} columns={tableColumns[index]} tableData={table} expand={index === 0} />;
        })}
      </div>
    );
  }, [STORY_FEATURES]);

  function handleSelectedOption(option: Option) {
    setSelectedPlan(option);
  }

  const memoMobileTableUi = React.useMemo(() => {
    const mobileColumnVisbility = {
      label: true,
      starter: selectedPlan.value === "Starter",
      team: selectedPlan.value === "Team",
      business: selectedPlan.value === "Business",
      enterprise: selectedPlan.value === "Enterprise",
    };
    return (
      <div className="tw-animate-fadeIn">
        {tableData.map((table, index) => {
          return (
            <FeaturesTable
              key={`mobile-table-${index}`}
              columns={tableColumns[index]}
              tableData={table}
              columnVisibility={mobileColumnVisbility}
              expand={index === 0}
            />
          );
        })}
      </div>
    );
  }, [selectedPlan]);

  return (
    <>
      {isMobile ? (
        <div className={styles.featuresMatrixMobile}>
          <div
            className="tw-sticky tw-top-0 tw-flex tw-w-full tw-items-center tw-bg-black tw-bg-opacity-60 tw-p-4 tw-backdrop-blur-sm"
            style={{ position: "-webkit-sticky", zIndex: "9999" }}
          >
            <SelectSection
              ariaLabel="featuresSelect"
              label="Features for plan"
              options={plans}
              setSelectedOption={handleSelectedOption}
              selectedOption={selectedPlan}
            />
          </div>

          {memoMobileTableUi}
        </div>
      ) : (
        <div className={styles.featuresMatrixDesktop}>{memoDesktopTableUi}</div>
      )}
    </>
  );
};
