import { IPlan, IPriceStructure } from ".";

export const starter: IPlan = {
  id: "starter",
  name: "Starter",
  description: "Unlimited Users. \nIncludes 1 live story. \nAdditional live stories $1/month",
  chargebee: new Set([
    "starter-monthly-aud",
    "starter-monthly-usd",
    "starter-monthly-eur",
    "starter-monthly-gbp",
    "starter-annual-aud",
    "starter-annual-usd",
    "starter-annual-eur",
    "starter-annual-gbp",
  ]),
  features: {
    "organisation-manage-publish-configurations": true,
    "organisation-team-leader-view": true,
    "organisation-team-limit": 1,
    "organisation-member-limit": 200,
    "organisation-team-member-limit": 200,
    "organisation-sites": true,
    "preview-banner": true,
    "page-settings-amp-enabled": false,
    "page-settings-analytics-ga": false,
    "page-settings-override-social-cover-url": true,
    "shorthand-analytics-tracking": true,
    "story-credits-required": false,
    "themes-demo": false,
    "publish-embed-ui": true,
    "story-comments": true,
  },
  marketing: {
    label: "An introduction to premium visual storytelling.",
    className: "plan-starter",
    benefits: ["Shorthand hosting", "Story embedding", "Shorthand Analytics", "Webinars and training"],

    prices: {
      planPrice: {
        monthly: { USD: 50 },
        yearly: { USD: 40 },
        nfp: { USD: 40 },
      },
      userAddonPrice: {
        monthly: { USD: 50 },
        yearly: { USD: 40 },
        nfp: { USD: 40 },
      },
    } as IPriceStructure,
  },
};
