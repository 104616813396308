import * as toolTipStyles from "../FeaturesMatrix/LabelCell.module.scss";
import * as styles from "./DiscountSwitch.module.scss";
import * as Switch from "@radix-ui/react-switch";
import * as Tooltip from "@radix-ui/react-tooltip";
import cx from "classnames";
import * as React from "react";
import { isMobile } from "react-device-detect";

type DiscountSwitchProps = {
  id: string;
  label: string;
  value: boolean;
  labelPosition?: "left" | "right";
  handleFunction: (value: boolean) => void;
};
export const DiscountSwitch = (props: DiscountSwitchProps): JSX.Element => {
  const { id, label, labelPosition, handleFunction, value } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div>
      <Tooltip.Provider delayDuration={800} skipDelayDuration={500}>
        {isMobile ? (
          <div className={cx(styles.switchWrapper, { [styles.rowReverse]: labelPosition === "left" })}>
            <Switch.Root className={styles.switchRoot} id={id} checked={value === true} onCheckedChange={handleFunction}>
              <Switch.Thumb className={styles.switchThumb} />
            </Switch.Root>
            <label className="Label" htmlFor={id}>
              {label}
            </label>

            <Tooltip.Root delayDuration={0} open={isOpen}>
              <Tooltip.Trigger asChild>
                <button
                  onTouchStart={() => setIsOpen(!isOpen)}
                  aria-label="Show discounts info"
                  className={styles.deviceTooltipTrigger}
                >
                  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 512 512">
                    <path
                      fill="#a3a3a3"
                      d="M464 256a208 208 0 1 0-416 0 208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0 256 256 0 1 1-512 0zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1 0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6-13.3 0-24-10.7-24-24v-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1 0-8.4-6.8-15.1-15.1-15.1h-58.3c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1-64 0z"
                    />
                  </svg>
                </button>
              </Tooltip.Trigger>
              <Tooltip.Content className={toolTipStyles.TooltipContent} onPointerDownOutside={() => setIsOpen(false)}>
                Registered not-for-profits that are non-governmental, non-commercial, and non-political save 40% on the price of Team,
                Business and Enterprise subscriptions when paying yearly.
                <Tooltip.Arrow className={toolTipStyles.TooltipArrow} />
              </Tooltip.Content>
            </Tooltip.Root>
          </div>
        ) : (
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger asChild>
              <div className={cx(styles.switchWrapper, { [styles.rowReverse]: labelPosition === "left" })}>
                <Switch.Root className={styles.switchRoot} id={id} checked={value === true} onCheckedChange={handleFunction}>
                  <Switch.Thumb className={styles.switchThumb} />
                </Switch.Root>
                <label className="Label" htmlFor={id}>
                  {label}
                </label>
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content className={toolTipStyles.TooltipContent}>
              Registered not-for-profits that are non-governmental, non-commercial, and non-political save 40% on the price of Team,
              Business and Enterprise subscriptions when paying yearly.
              <Tooltip.Arrow className={toolTipStyles.TooltipArrow} />
            </Tooltip.Content>
          </Tooltip.Root>
        )}
      </Tooltip.Provider>
    </div>
  );
};
