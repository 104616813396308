import { Enquiry } from "../components/Enquiry";
import { Footer } from "../components/Footer";
import { Heading } from "../components/Heading/Heading";
import {
  AboveTheFold,
  InfoSection,
  InfoSectionCtaContainer,
  InfoSectionMediaContainer,
  InfoSectionParagraph,
  InfoSectionTextContainer,
  LogosSection,
  MainVideo,
  SectionVideo,
  SignupCTA,
  Stories,
} from "../components/Homepage";
import { Layout } from "../components/Layout";
import { RewardsBadgeA, RewardsBadgeB, RewardsBadgeC } from "../components/RewardsBadges";
import { Honda } from "../components/SVGLogos";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { twMerge } from "tailwind-merge";

interface IProps {
  location: Record<any, string>;
  data: Record<any, any>;
}

export const CTA_TEXT = "Start creating";

const TESTIMONIAL = {
  text: "What attracted us to the tool was how easy it is to use. We wanted to be able to take locally produced content lying in front of us and have it on the web within 15 minutes.",
  author: "Nick Bennett, Honda",
};

const MAX_CONTAINER_WIDTH_STYLES = "min-xxxl:!tw-max-w-[2000px] tw-w-full tw-overflow-x-hidden";
const HEADING_THREE_STYLES =
  "!tw-font-bold !tw-p-0 !tw-mb-0 tw-text-left max-container:!tw-text-center !tw-text-5xl !tw-leading-[1.1] tw-text-white max-container:tw-w-full max-container:!tw-text-4xl max-sm:!tw-text-3xl";
const BUTTON_STYLES = "btn btn-secondary btn-inverse btn-lg !tw-m-0 !tw-font-bold max-sm:!tw-text-sm max-xl:!tw-text-lg";

const Index: React.FC<IProps> = props => {
  const [showPortal, setShowPortal] = React.useState(false);
  const { video } = props.data.homepageJson;
  const prePopulatedFields = React.useRef<Record<any, string>>({});

  function handleShowPortal(fields?: Record<any, string>): void {
    setShowPortal(true);
    prePopulatedFields.current = fields;
  }
  const portalProps = {
    type: "general-enquiry",
    subject: "general-enquiry",
    source: "marketing/home",
    prePopulatedFields: prePopulatedFields.current,
    onClose: () => {
      setShowPortal(false);
      prePopulatedFields.current = {};
    },
  };

  return (
    <Layout location={props.location} theme={"dark-theme"}>
      {showPortal && ReactDOM.createPortal(<Enquiry {...portalProps} />, document.getElementById("modal"))}
      <main className="marketing-content home tw-flex tw-w-full tw-flex-col tw-items-center tw-bg-black tw-text-white">
        <Helmet
          bodyAttributes={{
            class: "body-shorthand body-marketing body-marketing-home body-light hide-elevio",
          }}
        />
        <div className={twMerge(MAX_CONTAINER_WIDTH_STYLES, "tw-animate-fadeIn")}>
          {/* Above the fold */}
          <section className="tw-flex tw-w-full  tw-flex-col tw-items-center tw-pb-[67px] max-sm:!tw-pb-[50px]">
            <AboveTheFold setShowPortal={handleShowPortal} />
          </section>
          {/* main video */}
          <section className="tw-flex tw-w-full tw-justify-center tw-pb-[102px] tw-pt-[30px] max-sm:!tw-pb-[42px] max-sm:!tw-pt-0">
            <MainVideo />
          </section>
          {/* logos */}
          <section className="tw-flex tw-w-full tw-flex-col tw-items-center tw-pb-[67px] max-sm:!tw-pb-[18px]">
            <LogosSection heading="Loved by the world's most iconic brands" />
          </section>
          {/* proven brand platform */}
          <section className="tw-flex tw-w-full tw-pb-[102px] tw-pt-[90px] max-container:!tw-flex-col-reverse max-container:!tw-items-center max-sm:!tw-pb-[80px]">
            <InfoSection>
              <Heading headerType={3} className={HEADING_THREE_STYLES}>
                A proven <span className="tw-font-PassengerSerif !tw-font-semibold tw-italic"> brand</span>
                <br />
                <span className="tw-font-PassengerSerif !tw-font-semibold tw-italic"> publishing</span> platform
              </Heading>
              <InfoSectionTextContainer>
                <InfoSectionParagraph>
                  Shorthand powers the feature articles, digital magazines, proposals, internal comms, and annual reports of the
                  world&apos;s leading brands, publications, and media companies. Whatever story you&apos;re telling — you&apos;re in
                  great company.
                </InfoSectionParagraph>
              </InfoSectionTextContainer>
              <InfoSectionCtaContainer>
                <a href="https://app.shorthand.com/signup" className={BUTTON_STYLES}>
                  {CTA_TEXT}
                </a>
              </InfoSectionCtaContainer>
            </InfoSection>
            <InfoSectionMediaContainer>
              <div className="max-sm:!tw-rounded-0 max-container:!tw-bg-transparent tw-flex tw-w-full tw-justify-end tw-overflow-hidden  tw-pb-10 tw-pt-10 tw-pt-10 max-xxxl:tw-rounded-l-lg max-container:tw-pb-0 max-container:tw-pb-0 max-container:tw-pt-0 min-xxxl:tw-rounded-lg min-container:!tw-bg-secondary-Bossanova">
                <StaticImage
                  layout="constrained"
                  alt="Shorthand story image"
                  src="../../static/media/home/brand-placeholder.png"
                  className="tw-m-auto tw-h-full tw-overflow-hidden tw-rounded-l-lg tw-shadow-300 max-container:tw-rounded-lg min-container:tw-ml-14"
                  aria-hidden="true"
                  placeholder="dominantColor"
                />
              </div>
            </InfoSectionMediaContainer>
          </section>
          {/* Publish anywhere*/}
          <section className="tw-flex tw-w-full tw-flex-row-reverse tw-pb-[102px] tw-pt-[90px] max-container:!tw-flex-col-reverse max-container:!tw-items-center max-container:tw-pt-[30px] max-sm:!tw-pb-[80px] max-sm:tw-pt-0">
            <InfoSection>
              <Heading headerType={3} className={HEADING_THREE_STYLES}>
                Publish <span className="tw-font-PassengerSerif !tw-font-semibold tw-italic">anywhere </span>
                <br />
                on the web
              </Heading>
              <InfoSectionTextContainer>
                <InfoSectionParagraph>
                  Shorthand stories are beautiful in every browser on desktop and mobile. Publish to any web address, using AWS
                  hosting, your CMS, Shorthand hosting, or direct embedding into your existing website. Or secure your stories with
                  private and password-protected publishing.
                </InfoSectionParagraph>
              </InfoSectionTextContainer>
              <InfoSectionCtaContainer>
                <button
                  onClick={() =>
                    handleShowPortal({
                      interestedIn: "plans-and-pricing",
                      message: "exploring publishing options",
                    })
                  }
                  className={BUTTON_STYLES}
                >
                  Talk to us about publishing options
                </button>
              </InfoSectionCtaContainer>
            </InfoSection>
            <InfoSectionMediaContainer>
              <div className="max-sm:!tw-rounded-0 max-container:!tw-bg-transparent tw-h-auto !tw-overflow-hidden  tw-pb-10 tw-pt-10 max-xxxl:tw-rounded-r-lg max-container:tw-flex max-container:tw-justify-center max-container:tw-pb-0 max-container:tw-pt-0 min-xxxl:tw-rounded-lg min-container:tw-bg-secondary-Latte">
                <SectionVideo
                  video={video.publishAnywhere}
                  aspectRatio={4 / 3}
                  className="!tw-overflow-hidden tw-rounded-r-lg tw-shadow-300 max-container:tw-rounded-lg"
                />
              </div>
            </InfoSectionMediaContainer>
          </section>
          {/* Metrics */}
          <section className="tw-flex tw-w-full tw-pb-[102px] tw-pt-[90px] max-container:!tw-flex-col-reverse max-container:!tw-items-center max-sm:!tw-pb-[80px] max-sm:tw-pt-0">
            <InfoSection>
              <Heading headerType={2} className={HEADING_THREE_STYLES}>
                <span className="tw-font-PassengerSerif !tw-font-semibold tw-italic ">Measure & move </span>
                <br />
                your metrics
              </Heading>
              <InfoSectionTextContainer className="tw-w-[77%]">
                <InfoSectionParagraph>
                  The most engaging stories on the web are built with Shorthand. Our customers see up to 10x higher engagement with
                  their Shorthand stories than content created with their CMS.
                </InfoSectionParagraph>
                <InfoSectionParagraph>
                  With built-in, cookieless analytics, it&apos;s easy to measure results. Or, drop in your existing analytics tracking
                  code, tag managers, and ad pixels — so you always know how you&apos;re performing.
                </InfoSectionParagraph>
              </InfoSectionTextContainer>
              <InfoSectionCtaContainer>
                <a href="https://app.shorthand.com/signup" className={BUTTON_STYLES}>
                  {CTA_TEXT}
                </a>
              </InfoSectionCtaContainer>
            </InfoSection>
            <InfoSectionMediaContainer>
              <div className="tw-relative">
                <div className="max-sm:!tw-rounded-0 max-sm:!tw-bg-transparent max-container:!tw-bg-transparent tw-flex tw-h-auto tw-w-full tw-justify-center tw-overflow-hidden  tw-pb-10 tw-pt-10 max-xxxl:tw-rounded-l-lg max-container:tw-pb-0 max-container:tw-pt-0 min-xxxl:tw-rounded-lg min-container:tw-bg-brand_scale-50">
                  <div className="tw-max-w-[94%]">
                    <div className="tw-mb-4 tw-flex ">
                      <StaticImage
                        alt="A shorthand story"
                        src="../../static/media/home/story-card.png"
                        aria-hidden="true"
                        className="tw-h-auto tw-rounded-lg tw-shadow-300"
                        placeholder="dominantColor"
                      />
                      <StaticImage
                        alt="shorthand analytics"
                        src="../../static/media/home/analytics-card.png"
                        aria-hidden="true"
                        className="tw-ml-4 tw-h-auto tw-rounded-lg tw-shadow-300"
                        placeholder="dominantColor"
                      />
                    </div>
                    <StaticImage
                      alt="Shorthand integrations partners"
                      src="../../static/media/home/integrate.png"
                      aria-hidden="true"
                      className="tw-h-auto tw-rounded-lg tw-shadow-300"
                      placeholder="dominantColor"
                    />
                  </div>
                </div>
              </div>
            </InfoSectionMediaContainer>
          </section>
          {/* collaboration */}
          <section className="tw-flex tw-w-full tw-flex-row-reverse tw-pb-[102px] tw-pt-[90px] max-container:!tw-flex-col-reverse max-container:!tw-items-center max-container:tw-pt-[30px] max-sm:!tw-pb-[80px] max-sm:tw-pt-0">
            <InfoSection>
              <Heading headerType={2} className={HEADING_THREE_STYLES}>
                <span className="tw-font-PassengerSerif !tw-font-semibold tw-italic ">Loved </span>by the whole team
              </Heading>
              <InfoSectionTextContainer className="!tw-w-[78%]">
                <InfoSectionParagraph>
                  Streamline your workflows, and bring your editors, designers, and developers together in one collaborative workspace.
                  Beautiful templates and powerful storytelling features free up your team to focus on what matters.
                </InfoSectionParagraph>
                <div className="max-container:!tw-mw-auto tw-mt-[45px] tw-h-[46px] tw-w-[337px] max-container:!tw-me-auto max-container:!tw-ms-auto ">
                  <StaticImage
                    alt="shorthand story collaborators"
                    src="../../static/media/home/avatar-group.png"
                    aria-hidden="true"
                    placeholder="dominantColor"
                  />
                </div>
              </InfoSectionTextContainer>
            </InfoSection>
            <InfoSectionMediaContainer>
              <div className="tw-relative">
                <div className="max-sm:!tw-rounded-0 max-sm:!tw-bg-transparent tw-h-auto !tw-overflow-hidden  tw-pb-10 tw-pt-10 max-xxxl:tw-rounded-r-lg max-container:tw-flex max-container:tw-justify-center max-container:tw-pb-0 max-container:tw-pt-0 min-xxxl:tw-rounded-lg min-container:tw-bg-brand_scale-950">
                  <div className="tw-mr-14 max-container:tw-mr-0">
                    <SectionVideo
                      video={video.collaboration}
                      className="tw-relative -tw-left-[4px] !tw-overflow-hidden !tw-rounded-r-lg tw-shadow-300 max-container:tw-left-0 max-container:tw-rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </InfoSectionMediaContainer>
          </section>
          {/* scalable publishing */}
          <section className="tw-flex tw-w-full tw-pb-[102px] tw-pt-[90px] max-container:!tw-flex-col-reverse max-container:!tw-items-center max-container:tw-pt-[30px] max-sm:!tw-pb-[80px]  max-sm:tw-pt-0">
            <InfoSection>
              <Heading headerType={2} className={HEADING_THREE_STYLES}>
                Scalable,<span className="tw-font-PassengerSerif !tw-font-semibold tw-italic"> on-brand </span>
                <br />
                publishing
              </Heading>
              <InfoSectionTextContainer className="!tw-w-[78%]">
                <InfoSectionParagraph>
                  Scale your output across one team or your entire organisation — all while keeping everything beautifully on-brand.
                  Create visual consistency by incorporating your logos, colours, fonts, and styles into a handcrafted custom theme.
                </InfoSectionParagraph>
                <InfoSectionCtaContainer>
                  <a href="https://app.shorthand.com/signup" className={BUTTON_STYLES}>
                    {CTA_TEXT}
                  </a>
                </InfoSectionCtaContainer>
              </InfoSectionTextContainer>
            </InfoSection>
            <InfoSectionMediaContainer>
              <div className="max-sm:!tw-rounded-0 max-container:!tw-bg-transparent tw-flex tw-w-full tw-justify-end tw-overflow-hidden  tw-pb-10 tw-pt-10 tw-pt-10 max-xxxl:tw-rounded-l-lg max-container:tw-pb-0 max-container:tw-pb-0 max-container:tw-pt-0 min-xxxl:tw-rounded-lg min-container:tw-bg-secondary-Bossanova">
                <StaticImage
                  alt="Shorthand story image"
                  src="../../static/media/home/scalable-publishing-placeholder.png"
                  className="tw-m-auto tw-h-full tw-overflow-hidden tw-rounded-l-lg tw-shadow-300 max-container:tw-rounded-lg min-container:tw-ml-14"
                  aria-hidden="true"
                  placeholder="dominantColor"
                />
              </div>
            </InfoSectionMediaContainer>
          </section>
          {/* testimonial */}
          <section className="tw-relative tw-flex tw-w-full tw-flex-col tw-items-center tw-pb-[102px] tw-pt-[90px] max-sm:!tw-pb-[80px]">
            <Heading headerType={2} className={twMerge(HEADING_THREE_STYLES, "tw-max-w-[80%] max-sm:tw-max-w-[65%]")}>
              Creative teams <span className="tw-font-PassengerSerif !tw-font-semibold tw-italic ">love</span> using Shorthand
            </Heading>
            {/* mobile view */}
            <div className="tw-mt-6 tw-w-[50%] max-sm:!tw-w-[81%] min-container:!tw-hidden">
              <div className="tw-relative tw-flex tw-w-full tw-flex-col ">
                <StaticImage
                  alt="Shorthand testimonial"
                  src="../../static/media/home/testimonial.png"
                  className="tw-h-full"
                  aria-hidden="true"
                  placeholder="dominantColor"
                  quality={80}
                />
                <div className="tw-z-10 tw-mt-[-20px] tw-flex tw-w-full tw-justify-center">
                  <svg width={62} height={48} fill="none" className="tw-fill-brand_scale-600">
                    <path d="M15.624 22.2c-.128 1.792.896 2.56 3.328 3.456 4.224 1.664 6.784 4.736 6.784 9.344 0 7.424-6.144 12.544-13.184 12.544-7.168 0-11.776-5.248-11.776-12.672 0-12.8 9.728-26.368 28.416-33.92L31.24 8.12C21 12.856 16.008 18.872 15.624 22.2Zm30.592 0c-.128 1.792.896 2.56 3.328 3.456 4.224 1.664 6.784 4.736 6.784 9.344 0 7.424-6.144 12.544-13.184 12.544-7.168 0-11.776-5.248-11.776-12.672 0-12.8 9.728-26.368 28.416-33.92l2.048 7.168C51.592 12.856 46.6 18.872 46.216 22.2Z" />
                  </svg>
                </div>
              </div>
              <div className="tw-m-auto tw-ms-auto">
                <p className="!tw-m-0 !tw-mt-6 tw-text-center !tw-text-base tw-text-white">{TESTIMONIAL.text}</p>
                <p className="!tw-m-0 !tw-mt-4 tw-text-center !tw-text-base tw-text-white">{TESTIMONIAL.author}</p>
                <div className="!tw-m-0 !tw-mt-4">
                  <Honda className="tw-m-auto !tw-max-w-[175px] tw-fill-tones-white" />
                </div>
              </div>
              <div className="tw-mt-8 tw-flex tw-justify-center">
                <a href="https://shorthand.com/case-studies/" className={BUTTON_STYLES}>
                  Read our case studies
                </a>
              </div>
            </div>
            {/* desktop view */}
            <div className="tw-relative  tw-mt-[145px] tw-flex tw-w-full tw-justify-center max-container:!tw-hidden">
              <div className="tw-relative tw-z-0 tw-flex tw-aspect-video tw-w-[69%] tw-items-center tw-justify-end tw-rounded-lg  tw-bg-grey_scale-100 ">
                <div className="tw-flex tw-w-[45%] tw-flex-col tw-items-start tw-pr-[10%]">
                  <svg width={62} height={48} fill="none" className="tw-fill-brand_scale-600">
                    <path d="M15.624 22.2c-.128 1.792.896 2.56 3.328 3.456 4.224 1.664 6.784 4.736 6.784 9.344 0 7.424-6.144 12.544-13.184 12.544-7.168 0-11.776-5.248-11.776-12.672 0-12.8 9.728-26.368 28.416-33.92L31.24 8.12C21 12.856 16.008 18.872 15.624 22.2Zm30.592 0c-.128 1.792.896 2.56 3.328 3.456 4.224 1.664 6.784 4.736 6.784 9.344 0 7.424-6.144 12.544-13.184 12.544-7.168 0-11.776-5.248-11.776-12.672 0-12.8 9.728-26.368 28.416-33.92l2.048 7.168C51.592 12.856 46.6 18.872 46.216 22.2Z" />
                  </svg>
                  <p className="!tw-m-0 !tw-mt-[23px] tw-text-left !tw-text-2xl tw-font-medium tw-text-black ">{TESTIMONIAL.text}</p>
                  <p className="!tw-m-0 !tw-mt-[23px] tw-text-left  !tw-text-[22px] tw-text-black ">{TESTIMONIAL.author}</p>
                  <div className="!tw-m-0 !tw-mt-4">
                    <Honda className="tw-m-auto !tw-max-w-[175px] tw-fill-tones-black" />
                  </div>
                  <hr className="tw-border-1 tw-mb-[5%] tw-mt-[5%] tw-w-full tw-border-grey_scale-400" />
                  <a href="https://shorthand.com/case-studies/" className="btn btn-lg btn-primary !tw-m-0">
                    Read our case studies
                  </a>
                </div>
              </div>
              <div className="lightBorder tw-absolute tw-top-[-12%] tw-z-10 -tw-ml-[35%] tw-w-[30%] tw-overflow-hidden tw-rounded-lg">
                <StaticImage
                  alt="Shorthand testimonial"
                  src="../../static/media/home/testimonial.png"
                  className="tw-h-full"
                  aria-hidden="true"
                  placeholder="dominantColor"
                  quality={80}
                />
              </div>
            </div>
          </section>
          {/* Secure, accessible, and enterprise-ready */}
          <section className="tw-flex tw-w-full tw-flex-row-reverse tw-pb-[102px] tw-pt-[90px] max-container:!tw-flex-col-reverse max-container:!tw-items-center max-container:tw-pt-[30px] max-sm:!tw-pb-[80px] max-sm:tw-pt-0">
            <InfoSection>
              <Heading headerType={2} className={HEADING_THREE_STYLES}>
                Secure, accessible, <br />
                and <span className="tw-font-PassengerSerif !tw-font-semibold tw-italic">enterprise-ready </span>
              </Heading>
              <InfoSectionTextContainer className="!tw-w-[78%]">
                <InfoSectionParagraph>
                  With ISO 27001 certification and SOC 2 compliance, Shorthand is a proven enterprise solution and a trusted partner
                  for customers in government and regulated industries.
                </InfoSectionParagraph>
                <InfoSectionParagraph>
                  We are also experienced in partnering with customers to help them meet and exceed modern web accessibility standards.
                </InfoSectionParagraph>
                <InfoSectionCtaContainer>
                  <button onClick={() => setShowPortal(true)} className={BUTTON_STYLES}>
                    Talk to us about your requirements
                  </button>
                </InfoSectionCtaContainer>
              </InfoSectionTextContainer>
            </InfoSection>
            <InfoSectionMediaContainer>
              <div className="max-sm:!tw-rounded-0  max-container:!tw-bg-transparent tw-h-auto tw-w-full tw-overflow-hidden max-xxxl:tw-rounded-r-lg min-xxxl:tw-rounded-lg min-container:tw-bg-brand_scale-950">
                <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-pb-28 tw-pt-28 max-container:!tw-pb-0 max-container:!tw-pt-0">
                  <div className="tw-flex tw-w-full tw-justify-center tw-gap-10 max-sm:!tw-gap-4">
                    <div className="tw-w-[20%]">
                      <a
                        href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="G2 website"
                        className="tw-m-auto tw-mt-0"
                      >
                        <RewardsBadgeA />
                      </a>
                    </div>

                    <div className="tw-w-[20%]">
                      <a
                        href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="G2 website"
                        className="tw-m-auto tw-mt-0"
                      >
                        <RewardsBadgeB />
                      </a>
                    </div>

                    <div className="tw-w-[20%]">
                      <a
                        href="https://www.g2.com/products/shorthand/reviews?utm_source=rewards-badge"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="G2 website"
                        className="tw-m-auto tw-mt-0"
                      >
                        <RewardsBadgeC />
                      </a>
                    </div>
                  </div>
                  <div className="tw-mt-4 tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-10 max-sm:!tw-gap-4">
                    <div className="tw-flex tw-w-[20%] tw-justify-center">
                      <div className="tw-max-w-[138px]">
                        <trace-badge instance-id="41186615-dab4-45ef-aea2-06608703492e"></trace-badge>
                      </div>
                    </div>
                    <div className="tw-flex tw-w-[20%] tw-justify-center">
                      <a
                        href="https://cvs.babcert.com/babcert.asp?c=234513&v=53r3k1irp2"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="BAB certificate"
                      >
                        <StaticImage
                          src="../../static/media/features/badge-iso27001.png"
                          alt="ISO27001 badge"
                          className="tw-max-w-[100px]"
                          aria-hidden="true"
                          placeholder="blurred"
                        />
                      </a>
                    </div>
                    <div className="tw-flex tw-w-[20%] tw-justify-center">
                      <StaticImage
                        src="../../static/media/features/badge-soc2.png"
                        alt="SOC2 badge "
                        aria-hidden="true"
                        placeholder="blurred"
                        className="tw-max-w-[100px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </InfoSectionMediaContainer>
          </section>
          {/* code, no code */}
          <section className="tw-flex tw-w-full tw-pb-[102px] tw-pt-[90px] max-container:!tw-flex-col-reverse max-container:!tw-items-center max-container:tw-pt-[30px] max-sm:!tw-pb-[80px]  max-sm:tw-pt-0">
            <InfoSection>
              <Heading headerType={2} className={HEADING_THREE_STYLES}>
                Code or no-code:
                <br />
                <span className="tw-font-PassengerSerif tw-italic"> it&apos;s your choice</span>
              </Heading>
              <InfoSectionTextContainer className="!tw-w-[82%]">
                <InfoSectionParagraph>
                  Shorthand provides a simple drag-and-drop editing experience. With as much or as little customisation as you like,
                  Shorthand is a code-optional publishing platform. All business and enterprise plans come bundled with full access to
                  custom CSS, HTML and JavaScript to give you complete control.
                </InfoSectionParagraph>
                <InfoSectionCtaContainer>
                  <a href="https://app.shorthand.com/signup" className={BUTTON_STYLES}>
                    Try the <span className="tw-font-PassengerSerif tw-italic">beautifully simple</span> web editor
                  </a>
                </InfoSectionCtaContainer>
              </InfoSectionTextContainer>
            </InfoSection>
            <InfoSectionMediaContainer>
              <div className="max-sm:!tw-rounded-0 max-container:!tw-bg-transparent tw-flex tw-w-full tw-overflow-hidden tw-pb-10 tw-pt-10 tw-pt-10 max-xxxl:tw-rounded-l-lg max-container:tw-justify-center max-container:tw-pb-0 max-container:tw-pb-0 max-container:tw-pt-0 min-xxxl:tw-rounded-lg min-container:tw-justify-end min-container:tw-bg-secondary-Bossanova">
                <div className="tw-h-full max-container:tw-ml-0">
                  <SectionVideo
                    video={video.codeNoCode}
                    className="tw-relative -tw-right-[4px] !tw-overflow-hidden tw-rounded-l-lg tw-shadow-300 max-container:tw-rounded-lg"
                  />
                </div>
              </div>
            </InfoSectionMediaContainer>
          </section>
          {/*  tw-overflow-x-hidden tw-overflow-y-clip needed DO NOT REMOVE
                prevents the shapes in SignupCTA from producing unwanted scroll X
                and internal within this section 
                and the layout breaking at 620px to 1280px  */}
          {/* featured stories / Sign up Cta */}
          <section className="tw-relative tw-flex tw-w-full tw-flex-col tw-items-center  tw-overflow-x-hidden  tw-overflow-y-clip tw-pt-[90px]">
            <Heading headerType={2} className={HEADING_THREE_STYLES}>
              Get <span className="tw-font-PassengerSerif !tw-font-semibold tw-italic">inspired</span>
            </Heading>
            <div className="tw-mt-4 tw-w-[70%]">
              <p className="tw-text-center !tw-text-2xl tw-text-grey_scale-200 max-sm:!tw-text-base">
                See how some of the world's most recognised brands use Shorthand to build engaging visual stories.
              </p>
            </div>
            <div className="tw-mt-[100px] tw-w-full  max-sm:!tw-mt-0">
              <div className="tw-relative tw-top-[-100px] tw-z-50 max-sm:tw-top-0">
                <Stories />
              </div>
              <div className="tw-relative min-container:tw-top-[-200px]">
                <SignupCTA />
              </div>
            </div>
          </section>
        </div>
      </main>

      <div className="tw-relative tw-z-50 tw-mt-[-200px] max-container:tw-mt-0">
        <Footer />
      </div>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        name
        title
        tagline
        description
      }
    }
    homepageJson {
      video {
        codeNoCode {
          webM {
            publicURL
          }
          mp4 {
            publicURL
          }
          poster {
            src
          }
        }
        publishAnywhere {
          webM {
            publicURL
          }
          mp4 {
            publicURL
          }
          poster {
            src
          }
        }
        collaboration {
          webM {
            publicURL
          }
          mp4 {
            publicURL
          }
          poster {
            src
          }
        }
      }
    }
  }
`;
