import { Heading } from "../Heading/Heading";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { twMerge } from "tailwind-merge";

export const SignupCTAAlt = ({ heading }: { heading?: React.ReactNode }): JSX.Element => {
  const defaultHeading = (
    <>
      <span className="tw-font-PassengerSerif tw-italic">Beautifully simple </span> <br />
      storytelling
    </>
  );

  const sharedHeightClass = "!tw-h-[535px]";

  return (
    <section
      className={twMerge(
        "tw-relative  tw-flex  tw-w-full tw-items-center tw-justify-between tw-overflow-hidden tw-bg-black tw-text-white max-sm:!tw-mb-8 max-sm:!tw-mt-8 max-sm:!tw-h-auto max-sm:tw-p-0  max-sm:!tw-text-center",
        sharedHeightClass
      )}
    >
      <div className="tw-absolute -tw-right-[62px] tw-z-0 max-container:!tw-left-0 max-container:!tw-scale-[120%]  max-sm:!tw-scale-[75%] max-sm:!tw-pt-[120px] ">
        <div className="tw-relative tw-flex tw-justify-end tw-gap-9 max-container:!tw-left-0  max-container:!tw-mt-[-180px]   max-sm:tw-gap-4">
          <div data-id="left col" className="tw-flex tw-flex-col tw-gap-9">
            <div className="tw-aspect-square tw-w-[367px] tw-rounded-full tw-bg-brand_scale-950"></div>
            <div className="tw-aspect-square tw-w-[367px] tw-overflow-hidden tw-rounded-[50px]">
              <StaticImage
                alt="brand image"
                src="../../../static/media/home/footer-image.png"
                className="ObjectFit--cover"
                aria-hidden="true"
                placeholder="blurred"
                layout="fixed"
              />
            </div>
          </div>
          <div data-id="right col" className="tw-flex tw-flex-col tw-gap-y-7 ">
            <div className="tw-mt-[58px] tw-aspect-square tw-w-[367px]  tw-rounded-[50px] tw-bg-secondary-Bossanova"></div>
            <div className="tw-aspect-square tw-w-[367px] tw-rounded-full tw-bg-secondary-Bronze"></div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={twMerge(
            "tw-min-h-auto tw-absolute tw-top-0  tw-w-full  tw-bg-gradient-to-r tw-from-black tw-from-[43%]",
            sharedHeightClass
          )}
        ></div>
        <div
          className={twMerge("tw-z-100 tw-absolute tw-left-0 tw-top-0 tw-flex  tw-w-full  max-sm:!tw-pt-[20px]", sharedHeightClass)}
        >
          <div className="tw-ml-[10%] tw-flex tw-h-full tw-w-full  tw-items-center tw-gap-y-11">
            <div>
              <Heading headerType={2} className="!tw-pt-0  !tw-text-[52px] !tw-font-semibold ">
                {heading || defaultHeading}
              </Heading>
              <div>
                <a
                  href="https://app.shorthand.com/signup"
                  className="btn btn-lg btn-gradient !tw-m-0 max-sm:!tw-mt-[44px] min-sm:!tw-mt-[44px]"
                >
                  Start creating
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
