import { IPlan, IPriceStructure } from ".";

export const team: IPlan = {
  id: "team",
  name: "Team",
  description: "Per active user. Minimum of one active user. Includes 3 published stories. Additional published stories: $10/month.",
  chargebee: new Set([
    "team-monthly-aud",
    "team-monthly-usd",
    "team-monthly-eur",
    "team-monthly-gbp",
    "team-annual-aud",
    "team-annual-usd",
    "team-annual-eur",
    "team-annual-gbp",
  ]),
  features: {
    "organisation-manage-publish-configurations": true,
    "organisation-team-leader-view": true,
    "organisation-team-limit": 3,
    "organisation-member-limit": 200,
    "organisation-team-member-limit": 200,
    "organisation-sites": true,
    "page-settings-amp-enabled": false,
    "page-settings-analytics-ga": true,
    "page-settings-override-social-cover-url": true,
    "shorthand-analytics-tracking": true,
    "story-credits-required": false,
    "themes-demo": false,
    "publish-embed-ui": true,
    "story-comments": true,
    "theme-builder": true,
    "page-settings-password-protect-previews": true,
    "custom-hosting-limit": 1,
    "publish-configurations-download": true,
  },
  marketing: {
    label: "Build your own themes & structure your work with teams.",
    className: "plan-team",
    benefits: [
      "Downloadable story archives",
      "Password-protected previews",
      "Google Analytics",
      "Story analytics exports",
      "Basic theme builder",
      "Email support",
    ],

    prices: {
      planPrice: {
        monthly: { USD: 500 },
        yearly: { USD: 400 },
        nfp: { USD: 300 },
      },
      userAddonPrice: {
        monthly: { USD: 125 },
        yearly: { USD: 100 },
        nfp: { USD: 75 },
      },
      publishAddonPrice: {
        monthly: { USD: 30 },
        yearly: { USD: 24 },
        nfp: { USD: 18 },
      },
    } as IPriceStructure,
  },
};
